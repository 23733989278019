<template>
  <v-layout max-width max-height style="background:#fff;">
    <div class="  max-width mt-12 mx-12">
      <form @submit.prevent="saveAccountInfo">
        <div class="text-h6 liner-text mb-8 font-weight-bold">
          <div class="liner" />
          <div class=" text">配置密码要求：</div>
        </div>
        <div class=" mx-12 pb-4">
          <v-row no-gutters class="flex-y-center mb-2">
            <v-col cols="12" sm="4" md="4" class=" flex-justify-end" />
            <v-col cols="12" sm="6" md="6">
              <span class=" red--text">注：密码长度需大于0，小于等于255位</span>
            </v-col>
          </v-row>
          <v-row no-gutters class="flex-y-center mb-8">
            <v-col cols="12" sm="4" md="4" class=" flex-justify-end">
              <span class=" red--text mr-1">*</span>最小长度：
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <input v-model="data.PASSWORD_MIN" class=" default-input" required style="width:200px;" @input="changeMin">
              <span v-if="flagMin" class="pt-1 error--text">请设置一个大于0小于255的数字</span>
            </v-col>
          </v-row>
          <v-row no-gutters class="flex-y-center mb-8">
            <v-col cols="12" sm="4" md="4" class=" flex-justify-end">
              <span class=" red--text mr-1">*</span>最大长度：
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <input v-model="data.PASSWORD_MAX" class=" default-input" required style="width:200px;" @input="changeMax">
            </v-col>
          </v-row>
          <v-row no-gutters class="flex-y-center mb-0">
            <v-col cols="12" sm="4" md="4" class=" flex-justify-end">
              密码必须包含：
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <div class=" flex-y-center">
                <v-checkbox v-model="data.PASSWORD_INC" class="mr-4" label="小写字符" value="1" />
                <v-checkbox v-model="data.PASSWORD_INC" class="mr-4" label="大写字符" value="2" />
                <v-checkbox v-model="data.PASSWORD_INC" class="mr-4" label="数字" value="3" />
                <v-checkbox v-model="data.PASSWORD_INC" class="mr-4" label="特殊字符" value="4" />
              </div>
            </v-col>
          </v-row>
          <v-row no-gutters class="flex-y-center mb-3">
            <v-col cols="12" sm="4" md="4" class=" flex-justify-end">
              要求用户首次登录更改密码：
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-radio-group v-model="data.PASSWORD_FIRST" row dense class="">
                <v-radio label="是" value="1" />
                <v-radio label="否" value="0" />
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row no-gutters class="flex-y-center mb-8">
            <v-col cols="12" sm="4" md="4" class=" flex-justify-end">
              密码有效期（天）：
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <input v-model="data.PASSWORD_EXPIRE" class=" default-input" style="width:200px;">
            </v-col>
          </v-row>
          <!-- <div class=" flex-y-center mb-4">
                  <span class=" flex-0 flex-shrink-0 flex-justify-end mr-2 body-1" style="width:200px;">最小长度：</span>
                  <input class=" default-input" style="width:200px;" v-model="data.old"/>
              </div> -->
          <!-- <div class=" flex-y-center mb-0">
                  <span class=" flex-0 flex-shrink-0 flex-justify-end mr-2 body-1" style="width:200px;">最大长度：</span>
                  <input class=" default-input" style="width:200px;" v-model="data.old"/>
              </div>
              <div class=" flex-y-center mb-0">
                  <span class=" flex-0 flex-shrink-0 flex-justify-end mr-2 body-1" style="width:200px;">密码必须包含：</span>
                  <div class=" flex-y-center">
                  <v-checkbox v-model="selected" label="小写字符" value="1"></v-checkbox>
                  <v-checkbox v-model="selected" label="大写字符" value="2"></v-checkbox>
                  <v-checkbox v-model="selected" label="数字" value="3"></v-checkbox>
                  <v-checkbox v-model="selected" label="特殊字符" value="4"></v-checkbox>
                  </div>
              </div>
              <div class=" flex-y-center mb-0">
                  <span class=" flex-0 flex-shrink-0 flex-justify-end mr-2 body-1" style="width:200px;">要求用户首次登录更改密码：</span>
                  <v-radio-group v-model="data.status" row dense class="">
                      <v-radio label="是" value="1"></v-radio>
                      <v-radio label="否" value="0"></v-radio>
                  </v-radio-group>
              </div>
              <div class=" flex-y-center">
                  <span class=" flex-0 flex-shrink-0 flex-justify-end mr-2 body-1" style="width:200px;">密码有效期（天）：</span>
                  <input class=" default-input" style="width:200px;" v-model="data.old"/>
              </div> -->
        </div>
        <div class="text-h6 liner-text mb-5 font-weight-bold">
          <div class="liner" />
          <div class=" text">密码锁定要求</div>
        </div>
        <!-- <div class="text-h6 mb-5 mt-12">密码锁定要求</div> -->
        <div class=" ml-12">
          <v-row no-gutters class="flex-y-center mb-3">
            <v-col cols="12" sm="4" md="4" class=" flex-justify-end">
              可以锁定：
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-radio-group v-model="data.PASSWORD_LOCK" row dense class="">
                <v-radio label="是" value="1" />
                <v-radio label="否" value="0" />
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row v-if="data.PASSWORD_LOCK == '1'" no-gutters class="flex-y-center mb-3">
            <v-col cols="12" sm="4" md="4" class=" flex-justify-end">
              <span class=" red--text mr-1">*</span>允许连续失败次数：
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <input v-model="data.PASSWORD_FAILED" required class=" default-input" style="width:200px;" @input="changeTimes">
            </v-col>
          </v-row>
          <!-- <div class=" flex-y-center">
                  <span class=" flex-0 flex-shrink-0 flex-justify-end mr-2 body-1" style="width:200px;">可以锁定：</span>
                  <v-radio-group v-model="data.status" row dense class="">
                      <v-radio label="是" value="1"></v-radio>
                      <v-radio label="否" value="0"></v-radio>
                  </v-radio-group>
              </div>
              <div class=" flex-y-center">
                  <span class=" flex-0 flex-shrink-0 flex-justify-end mr-2 body-1" style="width:200px;">允许连续失败次数：</span>
                  <input class=" default-input" style="width:200px;" v-model="data.old"/>
              </div> -->
        </div>
        <v-row no-gutters class="flex-y-center mb-3 mt-12 pt-4">
          <v-col cols="12" sm="4" md="4" class=" flex-justify-end" />
          <v-col cols="12" sm="6" md="6">
            <v-btn depressed color="primary" height="32" width="120" :loading="btnLoading" type="submit">保存</v-btn>
          </v-col>
        </v-row>
      </form>
    </div>
  </v-layout>
</template>

<script>
export default {
  data() {
    return {
      data: {
        PASSWORD_INC: []
      },
      btnLoading: false,
      flagMin: false,
      flagMax: false
    }
  },
  mounted() {
    this.getAccountInfot()
  },
  methods: {
    // 获取账号设置
    getAccountInfot() {
      this.$http.get(this.$api.getAccountInfot, { data: null }).then(res => {
        this.btnLoading = false
        const _data = {}
        if (res.result && res.result.length) {
          res.result.forEach(item => {
            if (item.key === 'PASSWORD_INC') {
              _data[item.key] = item.value ? item.value.split(',') : []
            } else {
              _data[item.key] = item.value
            }
          })
        }
        this.data = _data
      }).catch(() => {
        this.btnLoading = false
      })
    },
    // 保存账号设置
    saveAccountInfo() {
      this.btnLoading = true
      const _data = this.$deepClone(this.data)
      if (_data.PASSWORD_LOCK !== '1') {
        _data.PASSWORD_FAILED = null
      }
      _data.PASSWORD_INC = this.data.PASSWORD_INC.join(',') || ''
      this.$http.post(this.$api.saveAccountInfo, { data: { ..._data }}).then(res => {
        this.btnLoading = false
        this.$message.success(res.result)
      }).catch(() => {
        this.btnLoading = false
      })
    },
    changeMin(e) {
      // if (!rx.test(e)) {
      //   this.flagMin = true
      // } else {
      //   this.flagMin = false
      // }
      this.data.PASSWORD_MIN = e.target.value ? (Number(e.target.value.replace(/\D+/, '')) || 1) : ''
      if (this.data.PASSWORD_MIN > 255) this.$set(this.data, 'PASSWORD_MIN', 255)
      if (this.data.PASSWORD_MAX && this.data.PASSWORD_MIN) {
        if (parseInt(this.data.PASSWORD_MIN) > parseInt(this.data.PASSWORD_MAX)) this.data.PASSWORD_MAX = this.data.PASSWORD_MIN
      }
    },
    changeMax(e) {
      this.data.PASSWORD_MAX = e.target.value ? (Number(e.target.value.replace(/\D+/, '')) || 1) : ''
      if (this.data.PASSWORD_MAX > 255) this.$set(this.data, 'PASSWORD_MAX', 255)
      if (this.data.PASSWORD_MAX && this.data.PASSWORD_MIN) {
        if (parseInt(this.data.PASSWORD_MIN) > parseInt(this.data.PASSWORD_MAX)) this.data.PASSWORD_MIN = this.data.PASSWORD_MAX
      }
    },
    changeTimes(e) {
      this.data.PASSWORD_FAILED = e.target.value ? (Number(e.target.value.replace(/\D+/, '')) || 1) : ''
    }
    // data.PASSWORD_FAILED" required class=" default-input" style="width:200px;" @input="changeTimes
  }
}
</script>

<style lang="scss" scoped>

.liner-text{
    position: relative;
    .liner{
        width:100%;
        height:1px;
        background-color: #ccc;
        position: absolute;
        top:50%;
    }
    .text {
        margin-left:50px ;
        padding-left: 15px;
        width:150px;
        height:22px;
        line-height: 22px;
        background-color: #fff;
        position: relative;
        z-index:3;
    }
}
</style>
